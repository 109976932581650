<template>
  <div
    ref="elementParent"
    class="glow-capture"
    :class="className"
    :style="{ 'position': 'relative', '--glow-size': size + 'px' }"
    v-bind="rest">
    <slot />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";

export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 400,
    },
    rest: {
      type: Object,
      default: () => ( {} ),
    },
  },
  setup () {
    const { isSafari } = useDevice();
    if ( isSafari ) {
      return;
    }

    const elementParent = ref( null );

    const move = ( e ) => {
      if ( e.pointerType === "mouse" ) {
        requestAnimationFrame( () => {
          const rect = elementParent.value.getBoundingClientRect();
          const x = e.pageX - rect.left - window.scrollX;
          const y = e.pageY - rect.top - window.scrollY;
          elementParent.value.style.setProperty( "--glow-x", `${x}px` );
          elementParent.value.style.setProperty( "--glow-y", `${y}px` );
        } );
      }
    };

    const leave = () => {
      elementParent.value.style.removeProperty( "--glow-x" );
      elementParent.value.style.removeProperty( "--glow-y" );
    };

    onMounted( () => {
      elementParent.value.addEventListener( "pointermove", move, { passive: true } );
      elementParent.value.addEventListener( "pointerleave", leave, { passive: true } );
    } );

    onUnmounted( () => {
      if ( !elementParent.value ) {
        return;
      }
      elementParent.value.removeEventListener( "pointermove", move );
      elementParent.value.removeEventListener( "pointerleave", leave );
    } );

    return {
      elementParent,
    };
  },
};
</script>
